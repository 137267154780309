import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spinner, Button } from 'react-bootstrap';
import swal from '@sweetalert/with-react';
import instance from "../../helpers/api.instance";
import { notification } from "../../helpers/Helpers";
import formato from '../../assets/files/formatodeaclaracion.docx'
import  ManualDateRangePicker  from '../../components/selects/ManualDateRangePicker';
import ReporteMonederos from '../../components/reportes/ReporteMonederos';

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};

const modalContentStyle = {
  background: '#fff',
  padding: '2rem',
  borderRadius: '8px',
  width: '90%',
  maxWidth: '500px',
  textAlign: 'center',
};

const Edenred = ({ setloading, userInfo, usuario }) => {
  const [cadenaComercial, setCadenaComercial] = useState('');
  const [numeroTienda, setNumeroTienda] = useState('');
  const [data, setData] = useState([]);
  const [totales, setTotales] = useState(null);
  const [tienda, settienda] = useState(null);
  const [tiendas, settiendas] = useState([]);
  const [canModify, setCanModify] = useState(false);
  const [periodo, setPeriodo] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [loading, setLoading] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const isPeriodActive = (periodo) => {
    if (!periodo || !periodo.fecha_inicio || !periodo.fecha_fin) return false;
    const today = new Date();
    const start = new Date(periodo.fecha_inicio);
    const end = new Date(periodo.fecha_fin);
    return today >= start && today <= end;
  };

  useEffect(() => {
    if (!cadenaComercial) {
      const randomChain = Math.random() < 0.5 ? '1' : '2';
      setCadenaComercial(randomChain);
    }
  }, [cadenaComercial]);

  const handleRangeChange = useCallback((range) => {
    setDateRange(range);
  }, []);

  const handleCadenaComercial = (cadena) => {
    setCadenaComercial(cadena);
    settienda('');
    setNumeroTienda('');
    settiendas([]);
  };

  useEffect(() => {
    if (!cadenaComercial) return;
    setloading(true);
    instance
      .get(`/tiendas/listaTiendas/${cadenaComercial}`)
      .then((response) => {
        settiendas(response.data.tiendas);
      })
      .catch((error) => {
        console.error('Error al obtener tiendas:', error);
      })
      .finally(() => setloading(false));
  }, [cadenaComercial, setloading]);

  useEffect(() => {
    if (tiendas.length > 0 && !numeroTienda) {
      const randomIndex = Math.floor(Math.random() * tiendas.length);
      const randomStore = tiendas[randomIndex];
      setNumeroTienda(randomStore.numeroTienda);
      settienda(randomStore.numeroTienda);
    }
  }, [tiendas, numeroTienda]);

  const fetchData = async () => {
    if (numeroTienda.trim() === '') {
      setData([]);
      setCanModify(false);
      return;
    }
    setLoading(true);
    try {
      const params = {
        cadena_comercial: cadenaComercial,
        numero_tienda: numeroTienda,
      };

      const response = await instance.get('/monederos', { params });
      console.log('Get Response Monederos:', response);

      if (response.data && response.data.length > 0) {
        setData(response.data);
        setTotales(response.totales || null);
        setPeriodo(response.periodo || []);
        setCanModify(Boolean(response.can_modify));
        notification('¡Se encontraron resultados!', 'success');
      } else {
        setData([]);
        setTotales(null);
        setPeriodo([]);
        notification('No se encontraron datos', 'warning');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
      notification('Error al obtener los datos.', 'error');
    } finally {
      setLoading(false);
    }
  };

  // useEffect para actualizar los datos cuando cambien los filtros
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cadenaComercial, numeroTienda]);

  const handleEstatusChange = async (numeroEmpleado, nuevoEstatus) => {
    let confirmationMessage = '';
  
    switch (nuevoEstatus) {
      case 'correcto':
        confirmationMessage = '¿Está seguro de que desea marcar como correcto este registro?';
        break;
      case 'nuevo':
        confirmationMessage = '¿Está seguro de que desea marcar como nuevo este registro?';
        break;
      case 'reposicion':
        confirmationMessage =
          'Al marcar como reposicion, se asignará un nuevo monedero al colaborador.';
        break;
      case 'incapacidad':
        confirmationMessage =
          'Al marcar como incapacidad, no se asignará un nuevo monedero.';
        break;
      default:
        return;
    }

     // Esperamos la confirmación del usuario
    const willProceed = await swal({
      title: 'Confirmación',
      text: confirmationMessage,
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
      dangerMode: true,
    });
  
    if (willProceed) {
      setLoading(true);
      try {
        const requestBody = {
          numeroEmpleado,
          estatus: nuevoEstatus,
        };

        // Desestructuramos la respuesta para obtener "data" que es donde está la información enviada desde el backend
      const data = await instance.post('/monederos/valida_informacion', requestBody);
      console.log('Post Response Monederos:', data);

        // Revisamos el status que nos envía el backend y mostramos la notificación correspondiente
        if (data.status === true) {
          await fetchData();
          notification(data.messages, 'success');
        } else if (data.status === false) {
          notification(data.messages, 'warning');
        } else {
          notification(data.messages || 'No se pudo cambiar el estatus', 'error');
        }
      } catch (error) {
        console.error('Error changing estatus:', error);
        // Si el error viene con una respuesta clara, se muestra ese mensaje
        if (error.response && error.response.data && error.response.data.message) {
          notification(error.response.data.message, 'error');
        } else {
          notification('Ocurrió un error al actualizar el estatus.', 'error');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;  // Convertimos a formato yyyy/mm/dd
  };

  const handleSendDates = async () => {
    if (!dateRange.startDate || !dateRange.endDate) {
      alert('Por favor selecciona ambas fechas');
      return;
    }

    setLoading(true);

    try {
      const requestBody = {
        fechaInicio: formatDate(dateRange.startDate),
        fechaFin: formatDate(dateRange.endDate),
      };

      const response = await instance.post('/monederos/nuevo_periodo', requestBody);
      const { status, message } = response;

      if (status === true) {
        setShowCalendarModal(false);
        notification(message, 'success');
        fetchData();
      } else {
        alert(message);
        notification(message, 'error');
      }
    } catch (error) {
      console.error('Error al enviar fechas:', error);
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message);
      } else {
        alert('Ocurrió un error al enviar los datos.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTienda = (e) => {
    settienda(e.target.value);
    setNumeroTienda(e.target.value);
  };

  const openCalendarModal = () => {
    setShowCalendarModal(true);
  };
  
  return (
    <>
      <div className="row flex-column mt-n1rem5 gap-3">
        {userInfo?.permisos?.includes('monederos.view.any') && (
          <div className="flex-wrap text-center justify-content-center row my-3 mb-3">
            <div className="col col-lg-3">
              <label htmlFor="cadenaComercial">Línea de negocio:</label>
              <div className="form-label-group w-100">
                <select
                  name="Categoria"
                  id="categoria"
                  className="form-control mb-rem5 mw-50"
                  onChange={(e) => handleCadenaComercial(e.target.value)}
                  value={cadenaComercial}
                >
                  <option value="1">Liverpool</option>
                  <option value="2">Suburbia</option>
                </select>
              </div>
            </div>

            {tiendas.length > 0 && (
              <div className="col col-lg-3">
                <label htmlFor="cadenaComercial">Tiendas:</label>
                <div className="form-label-group w-100">
                  <select
                    name="tipoUsuario"
                    className="form-control mb-rem5"
                    onChange={(e) => handleTienda(e)}
                    value={tienda}
                  >
                    <option value="">Selecciona</option>
                    {tiendas.map((t) => (
                      <option value={t.numeroTienda} key={t.numeroTienda}>
                        {t.numeroTienda} - {t.nombreTienda}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="row my-2 d-flex justify-content-center align-items-center">
          {/* Columna del calendario */}
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center text-center mb-1">
            <h5>
              Periodo {periodo ? (isPeriodActive(periodo) ? 'vigente' : 'vencido') : 'actual:'}
            </h5>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Inicio</th>
                  <th>Fin</th>
                </tr>
              </thead>
              <tbody>
                {periodo ? (
                  <tr>
                    <td>{periodo.fecha_inicio}</td>
                    <td>{periodo.fecha_fin}</td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="2" className="text-center">No hay período registrado</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {userInfo?.permisos?.includes('monederos.periodos.create') && (
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center mb-1">
            <Button variant="primary" onClick={openCalendarModal} disabled={loading}>
              {loading ? 'Procesando...' : 'Nuevo Periodo'}
            </Button>
          </div>
          )}

          {/* Columna de totales */}
          {totales && (
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center text-center mb-1 mr-1">
              <h5>Totales:</h5>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Disponibles</th>
                    <th>Otorgados</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>General</strong></td>
                    <td>{totales.disponibles}</td>
                    <td>{totales.otorgados}</td>
                  </tr>
                  <tr>
                    <td><strong>Lista A</strong></td>
                    <td>{totales.a.disponibles}</td>
                    <td>{totales.a.otorgados}</td>
                  </tr>
                  <tr>
                    <td><strong>Lista B</strong></td>
                    <td>{totales.b.disponibles}</td>
                    <td>{totales.b.otorgados}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {/* Columna para descargar reporte */}
          {userInfo?.permisos?.includes('monederos.view.any') && (
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center text-center mb-1">
              <ReporteMonederos className="w-100 text-center" />
            </div>
          )}
        </div>
        

        <div className="col-lg-12 col-md-12 mt-1rem5">
          <div className="rounded card p-3 mb-5" style={{ overflowX: 'auto' }}>
            {/* Indicador de carga */}
            {loading && (
              <div className="text-center my-3">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {/* Tabla de datos */}
            {!loading && (
              <>
                <Table id="edenred-rank" className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th className="align-middle table-dark">ID</th>
                      <th className="align-middle table-dark">Tienda</th>
                      <th className="align-middle table-dark"># Empleado</th>
                      <th className="align-middle table-dark">Asesor</th>
                      <th className="align-middle table-dark">Puesto</th>
                      <th className="align-middle table-dark">Número de Monedero</th>
                      <th className="align-middle table-dark">Es correcto</th>
                      <th className="align-middle table-dark">Solicitar Nuevo Monedero</th>
                      <th className="align-middle table-dark">Solicitar Reposición</th>
                      <th className="align-middle table-dark">Marcar en caso de baja o incapacidad</th>
                    </tr>
                  </thead>
                  <tbody className="table-bordered">
                    {data.length > 0 ? (
                      data.map((item) => (
                        <tr key={item.numero}>
                          <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.tienda?.id ?? 'N/A'}</td>
                          <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.tienda?.nombre ?? 'N/A'}</td>
                          <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.numero ?? 'N/A'}</td>
                          <td className="align-middle" style={{ verticalAlign: 'middle' }}>
                            {item.nombre || item.apaterno || item.amaterno
                              ? `${item.nombre || ''} ${item.apaterno || ''} ${item.amaterno || ''}`.trim()
                              : 'N/A'}
                          </td>
                          <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.area ?? 'N/A'}</td>
                          <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.monedero ?? 'N/A'}</td>
                          {/* Checkbox para "Es correcto" */}
                          <td
                            className="align-middle text-center"
                            style={{
                              verticalAlign: 'middle',
                              backgroundColor: item.estatus === 'correcto' ? '#d4edda' : '',
                            }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked={item.estatus === 'correcto'}
                              onClick={(e) => {
                                if (!canModify) return;
                                if (item.estatus !== 'correcto') {
                                  handleEstatusChange(item.numero, 'correcto');
                                } else {
                                  // Si ya está marcado, se evita el cambio (para no desmarcarlo)
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                cursor: canModify && item.estatus !== 'correcto' ? 'pointer' : 'default',
                              }}
                            />
                          </td>
                          {/* Checkbox para "Solicitar Nuevo Monedero" */}
                          <td
                            className="align-middle text-center"
                            style={{
                              verticalAlign: 'middle',
                              backgroundColor: item.estatus === 'nuevo' ? '#fff3cd' : '',
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={item.estatus === 'nuevo'}
                              onClick={(e) => {
                                if (!canModify) return;
                                if (item.estatus !== 'nuevo') {
                                  handleEstatusChange(item.numero, 'nuevo');
                                } else {
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                cursor: canModify && item.estatus !== 'extravio' ? 'pointer' : 'default',
                              }}
                            />
                          </td>
                          {/* Checkbox para "Solicitar Reposición" */}
                          <td
                            className="align-middle text-center"
                            style={{
                              verticalAlign: 'middle',
                              backgroundColor: item.estatus === 'reposicion' ? '#fff3cd' : '',
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={item.estatus === 'reposicion'}
                              onClick={(e) => {
                                if (!canModify) return;
                                if (item.estatus !== 'reposicion') {
                                  handleEstatusChange(item.numero, 'reposicion');
                                } else {
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                cursor: canModify && item.estatus !== 'reposicion' ? 'pointer' : 'default',
                              }}
                            />
                          </td>
                          {/* Checkbox para "Marcar en caso de incapacidad" */}
                          <td
                            className="align-middle text-center"
                            style={{
                              verticalAlign: 'middle',
                              backgroundColor: item.estatus === 'incapacidad' ? '#f8d7da' : '',
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={item.estatus === 'incapacidad'}
                              onClick={(e) => {
                                if (!canModify) return;
                                if (item.estatus !== 'incapacidad') {
                                  handleEstatusChange(item.numero, 'incapacidad');
                                } else {
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                cursor: canModify && item.estatus !== 'incapacidad' ? 'pointer' : 'default',
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No hay datos disponibles
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            )}

            {/* Botón para descargar el archivo y texto adicional */}
            {/* <div className="mt-3 text-left">
              <a href={formato} download className="btn btn-primary">
                Descargar Formato
              </a>
              <p className="mt-2">
                Este formato se deberá enviar por correo a <strong>atencionpif@centrodeseguros.com.mx</strong> | <strong>ghernandez@gpmass.com</strong> | <strong>jaromerow@liverpool.com.mx</strong>
                <br />
                Con Asunto: <strong>Aclaración monedero nombre | tienda</strong>
              </p>
            </div> */}
          </div>
        </div>
      </div>

      {showCalendarModal && (
        <div style={modalOverlayStyle} onClick={() => setShowCalendarModal(false)}>
          <div
            style={modalContentStyle}
            onClick={(e) => e.stopPropagation()}  // Impide que se cierre al hacer clic en el contenido
          >
            <h3>Seleccionar nuevo periodo</h3>
            <p>Por favor, selecciona el rango de fechas para el nuevo periodo.</p>
            <ManualDateRangePicker onRangeChange={handleRangeChange} />
            <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="secondary" onClick={() => setShowCalendarModal(false)}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleSendDates} disabled={loading}>
                {loading ? 'Procesando...' : 'Registrar Fechas'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Edenred;
