import React, { useState } from 'react';
import instance from '../../helpers/api.instance';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ReporteMonederos = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const handleDownloadExcel = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await instance.get('/monederos/estatus_monederos_usuarios');
      
      if (!response || !response.data) {
        throw new Error('La respuesta del servidor no contiene datos válidos.');
      }      
      
      const reportData = response.data;
      if (reportData.length === 0) {
        toast.warning('No hay datos disponibles para generar el reporte.');
        setLoading(false);
        return;
      }

      // Crear un nuevo libro de Excel
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Reporte Monederos');
      worksheet.views = [{ state: 'frozen', ySplit: 1 }];

      // Estilos de encabezado
      const titleStyle = {
        font: { bold: true, size: 14, color: { argb: 'FFFFFFFF' } },
        alignment: { vertical: 'middle', horizontal: 'center' },
        fill: { type: 'pattern', pattern:'solid', fgColor:{ argb:'555555' } }
      };

      // Estilos de celdas
      const cellStyle = {
        font: { size: 12 },
        alignment: { vertical: 'middle', horizontal: 'center' },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        },
      };

      // Definir las columnas
      worksheet.columns = [
        { header: 'Negocio', key: 'negocio', width: 20 },
        { header: 'Zona', key: 'zona', width: 15 },
        { header: 'Número Tienda', key: 'numeroTienda', width: 20 },
        { header: 'Nombre Tienda', key: 'nombreTienda', width: 25 },
        { header: 'Número Empleado', key: 'numeroEmpleado', width: 25 },
        { header: 'Nombres', key: 'nombres', width: 20 },
        { header: 'Apellido Paterno', key: 'apPat', width: 23 },
        { header: 'Apellido Materno', key: 'apMat', width: 23 },
        { header: 'Perfil', key: 'nombrePerfil', width: 35 },
        { header: 'Monedero Anterior', key: 'monedero_antepasado', width: 25 },
        { header: 'Monedero Pasado', key: 'monedero_pasado', width: 25 },
        { header: 'Monedero Actual', key: 'monedero_actual', width: 25 },
        { header: 'Fecha Monedero', key: 'fecha_monedero', width: 20 },
        { header: 'Estatus Actual', key: 'estatus_actual', width: 20 }
      ];

      // Aplicar estilos a la cabecera
      worksheet.getRow(1).eachCell(cell => {
        Object.assign(cell, titleStyle);
      });

      // Agregar datos
      reportData.forEach((item, index) => {
        const row = worksheet.addRow({
          negocio: item.negocio || 'N/A',
          zona: item.zona || 'N/A',
          numeroTienda: item.numeroTienda || 'N/A',
          nombreTienda: item.nombreTienda || 'N/A',
          numeroEmpleado: item.numeroEmpleado || 'N/A',
          nombres: item.nombres || 'N/A',
          apPat: item.apPat || 'N/A',
          apMat: item.apMat || 'N/A',
          nombrePerfil: item.nombrePerfil || 'N/A',
          monedero_antepasado: item.monedero_antepasado || 'N/A',
          monedero_pasado: item.monedero_pasado || 'N/A',
          monedero_actual: item.monedero_actual || 'N/A',
          fecha_monedero: item.fecha_monedero || 'N/A',
          estatus_actual: item.estatus_actual || 'N/A'
        });
        row.eachCell(cell => Object.assign(cell, cellStyle));
      });

      // Generar archivo Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const fileName = `Reporte_Monederos_${new Date().toISOString().slice(0, 10)}.xlsx`;
      saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), fileName);

      toast.success('Reporte generado correctamente.');
    } catch (error) {
      setError(error.message);
      toast.error('Error al generar el reporte: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      {error && <Alert variant="danger">{error}</Alert>}
      <Button variant="primary" onClick={handleDownloadExcel} disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Descargar Reporte'}
      </Button>
    </div>
  );
};

export default ReporteMonederos;
