import { useEffect, useState } from "react";

function ManualDateRangePicker({ onRangeChange }) {
    // Estados: fecha de inicio, fecha de fin, mes/año visibles
    const today = new Date();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    // Mes y año que se muestran en el calendario
    const [visibleMonth, setVisibleMonth] = useState(today.getMonth());
    const [visibleYear, setVisibleYear] = useState(today.getFullYear());
  
    // Función para generar los días que se muestran en la cuadrícula
    // Retorna un array de 42 valores (7 días x 6 filas), con cada día o 'null' para celdas vacías
    const generateCalendarDays = (month, year) => {
      // 1) Fecha del día 1
      const firstDay = new Date(year, month, 1);
      // 2) Día de la semana del 1er día (0 = domingo, 1 = lunes, ...)
      const startWeekday = firstDay.getDay(); 
      // 3) Cantidad de días que tiene este mes
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      
      // Array total de 42 celdas (7 columnas x 6 filas)
      const calendarCells = new Array(42).fill(null);
  
      // Rellenar las celdas donde hay días del mes
      for (let day = 1; day <= daysInMonth; day++) {
        const index = startWeekday + (day - 1);
        calendarCells[index] = new Date(year, month, day);
      }
  
      return calendarCells;
    };
  
    // Función para saber si un día (objeto Date) está dentro del rango seleccionado
    const isWithinSelectedRange = (dateObj) => {
      if (!startDate || !endDate) return false;
      const time = dateObj.getTime();
      return time >= startDate.getTime() && time <= endDate.getTime();
    };
  
    // Maneja el clic en un día
    const handleDayClick = (dateObj) => {
      if (!startDate) {
        // Si no hay startDate aún, lo asignamos
        setStartDate(dateObj);
        setEndDate(null);
      } else if (startDate && !endDate) {
        // Si hay startDate, pero no endDate, decidir si dateObj es antes o después:
        if (dateObj < startDate) {
          // Si el usuario hace clic en una fecha anterior al inicio, la tomamos como el "nuevo" inicio
          setStartDate(dateObj);
        } else {
          // Caso normal: definimos endDate
          setEndDate(dateObj);
        }
      } else {
        // Si ya existía un startDate y un endDate, se resetea con la nueva fecha de inicio
        setStartDate(dateObj);
        setEndDate(null);
      }
    };
  
    // Mover al mes anterior
    const handlePrevMonth = () => {
      let newMonth = visibleMonth - 1;
      let newYear = visibleYear;
      if (newMonth < 0) {
        newMonth = 11;
        newYear -= 1;
      }
      setVisibleMonth(newMonth);
      setVisibleYear(newYear);
    };
  
    // Mover al mes siguiente
    const handleNextMonth = () => {
      let newMonth = visibleMonth + 1;
      let newYear = visibleYear;
      if (newMonth > 11) {
        newMonth = 0;
        newYear += 1;
      }
      setVisibleMonth(newMonth);
      setVisibleYear(newYear);
    };
  
    // Cada vez que startDate o endDate cambian, notificamos al padre con onRangeChange
    useEffect(() => {
      onRangeChange({ startDate, endDate });
    }, [startDate, endDate, onRangeChange]);
  
    // Generamos las celdas de calendario para el mes visible
    const calendarCells = generateCalendarDays(visibleMonth, visibleYear);
    // Nombre de los días abreviados
    const weekdays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'];
    // Nombre de los meses
    const monthNames = [
      'Enero','Febrero','Marzo','Abril','Mayo','Junio',
      'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'
    ];
  
    // Estilos en línea (puedes personalizar lo que gustes)
    const calendarContainerStyle = {
        display: 'inline-block',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: '6px',
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        color: '#333',
        border: '1px solid #ccc',
    };
  
    const headerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '0.5rem',
        fontWeight: 'bold',
    };
  
    const arrowStyle = {
        cursor: 'pointer',
        fontSize: '1.2rem',
        userSelect: 'none',
    };
  
    const daysHeaderStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        marginBottom: '0.5rem',
        fontWeight: 'bold',
    };
  
    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: '1px',
    };
  
    const cellStyle = {
        cursor: 'pointer',
        height: '24px',
        lineHeight: '24px',
        textAlign: 'center',
        borderRadius: '3px',
    };
  
    return (
      <div style={calendarContainerStyle}>
        {/* Encabezado con mes y navegación */}
        <div style={headerStyle}>
          <span style={arrowStyle} onClick={handlePrevMonth}>{'<'}</span>
          <strong>
            {monthNames[visibleMonth]} {visibleYear}
          </strong>
          <span style={arrowStyle} onClick={handleNextMonth}>{'>'}</span>
        </div>
  
        {/* Días de la semana */}
        <div style={daysHeaderStyle}>
          {weekdays.map((wd) => (
            <div key={wd} style={{ fontWeight: 'bold' }}>{wd}</div>
          ))}
        </div>
  
        {/* Cuadrícula de días */}
        <div style={gridStyle}>
          {calendarCells.map((dateObj, idx) => {
            if (!dateObj) {
              // Celdas vacías (antes del 1 o después del 30/31)
              return <div key={idx} />;
            }
  
            // Verificamos si este día está seleccionado como inicio o fin
            const isStart = startDate && dateObj.toDateString() === startDate.toDateString();
            const isEnd = endDate && dateObj.toDateString() === endDate.toDateString();
            const inRange = isWithinSelectedRange(dateObj);
  
            // Estilos para resaltar el rango
            let backgroundColor = '';
            let color = '#000';
            if (isStart || isEnd) {
              backgroundColor = '#007bff'; // azul
              color = '#fff';
            } else if (inRange) {
              backgroundColor = '#cce5ff'; // azul claro
            }
  
            return (
              <div
                key={idx}
                style={{
                  ...cellStyle,
                  backgroundColor,
                  color
                }}
                onClick={() => handleDayClick(dateObj)}
              >
                {dateObj.getDate()}
              </div>
            );
          })}
        </div>
  
        {/* Mostrar el rango seleccionado */}
        {(startDate || endDate) && (
            <div style={{ marginTop: '0.5rem', fontSize: '12px' }}>
            <p style={{ margin: 0 }}>
                <strong>Inicio:</strong> {startDate ? startDate.toLocaleDateString() : '---'}
            </p>
            <p style={{ margin: 0 }}>
                <strong>Fin:</strong> {endDate ? endDate.toLocaleDateString() : '---'}
            </p>
            </div>
        )}
      </div>
    );
}

export default ManualDateRangePicker;